.home-hero{
    color: #fff;
    background-position: center;
    height: 100vh;
    min-height: 80rem;
    max-height: 120rem;
    position: relative;
    background: linear-gradient(to right,rgba(245,245,245,.8),rgba(245,245,245,.8)),url(../img/common-bg.svg);

    &__content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 90rem;
        width: 92%;
    
        .heading-primary{
            font-size: 6rem;
            text-transform: uppercase;
            letter-spacing: 3px;
            text-align: center;
            color: #111;
            font-weight: 800;        
        }
    }

    &__info{
        margin: 3rem auto 0 auto;
        max-width: 80rem;

        .text-primary-info{
            color: #333;
            font-size: 2.2rem;
            text-align: center;
            width: 100%;
            line-height: 1.6;
        }
    }

    &__cta{
        margin-top: 5rem;
        text-align: center;

        .button--bg{
            padding: 1.5rem 8rem;
            font-size: 2rem;
        }

        .button{
            background: #7843e9;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: 1px;
            display: inline-block;
            font-weight: 700;
            border-radius: 5px;
            box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
            transition: transform .3s;
            text-decoration: none;

            &:hover{
                background-color: #552ea9;
            }
        }
    }

    &__socials{
        position: absolute;
        top: 40%;
        transform: translateY(50%);
        background: #fff;
        box-shadow: rgba(100,100,111,.2) 0 7px 29px 0;
        padding: 0.75rem;
    }

    &__social{
        width: 5rem;

        &-icon{
            width: 100%;

            &-link{
                display: block;
                width: 100%;
                padding: 1rem;
                transition: background .3s;
                border-radius: 5px;

                &:hover{
                    background: rgba(120,67,233,.2);
                }
            }

            img{
                width: 30px;
                height: 30px;
                color: #333;
            }
        }
    }
}