.contact{
    background: linear-gradient(to right,rgba(245,245,245,.8),rgba(245,245,245,.8)),url(../img/common-bg.svg);
    background-size: cover;
    background-position: center;
    padding: 12rem 0;
    
}

.heading{
    margin-bottom: 12rem;
    
    &-sec{
        &__main{
            display: block;
            font-size: 4rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            letter-spacing: 3px;
            text-align: center;
            margin-bottom: 3.5rem;
            position: relative;
            color: #111;
            font-weight: 700; 

            &::after{
                content: '';
                position: absolute;
                top: calc(100% + 1.5rem);
                height: 5px;
                width: 3rem;
                background: #7843e9;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 5px;
            }
        }

        &__sub{
            display: block;
            text-align: center;
            color: #555;
            font-size: 2rem;
            font-weight: 500;
            max-width: 80rem;
            margin: auto;
            line-height: 1.6;
        }
    }
}

.contact__form-container{
    box-shadow: rgba(100,100,111,.2) 0 7px 29px 0;
    background: #fff;
    padding: 4rem;
    margin-top: 6rem;
    max-width: 80rem;
    text-align: right;
    width: 95%;
    border-radius: 5px;
    margin: 5rem auto 0 auto;
}

.contact__form-field{
    margin-bottom: 4rem;
}

.contact__form-label{
    color: #666;
    font-size: 1.4rem;
    letter-spacing: 1px;
    font-weight: 700;
    margin-bottom: 1rem;
    display: block;
    text-align: left;
}

.contact__form-input{
    color: #333;
    padding: 2rem;
    width: 100%;
    border: 1px solid #ebebeb;
    font-size: 1.6rem;
    letter-spacing: 0;
    background: #f0f0f0;
    border-radius: 5px;
    outline: none;

    &::-ms-input-placeholder { /* Edge 12-18 */
        font-weight: 500;
    }
      
    &::placeholder {
        font-weight: 500;
    }
}

.btn{
    background: #7843e9;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    font-weight: 700;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
    transition: transform .3s;
    width: 30%;
    padding: 2rem 4rem;
    font-size: 1.6rem;

    &:hover,
    &:active,
    &:focus{
        background: #4e2b99!important;
        color: #fff!important;
    }

}

@media (max-width: 768px){
    .btn{
        width: 100%;
    }
}