.about,
.project{
    background-color: #fafafa;
    padding: 12rem 0;

    .main-container{
        max-width: 120rem;
        margin: auto;
        width: 92%;

        .heading{
            margin-bottom: 9rem;

            &-sec__main{
                display: block;
                font-size: 4rem;
                text-transform: uppercase;
                letter-spacing: 1px;
                letter-spacing: 3px;
                text-align: center;
                margin-bottom: 3.5rem;
                position: relative;
                color: #111;

                &::after{
                    content: '';
                    position: absolute;
                    top: calc(100% + 1.5rem);
                    height: 5px;
                    width: 6rem;
                    background: #7843e9;
                    left: 50%;
                    transform: translateX(-50%);
                    border-radius: 5px;
                }
            }

            &-sec__sub{
                display: block;
                text-align: center;
                color: #555;
                font-size: 2rem;
                font-weight: 500;
                max-width: 80rem;
                margin: auto;
                line-height: 1.6;
            }
        }
    }

    &__content{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10rem;

        &-title{
            font-weight: 700;
            font-size: 2.8rem;
            margin-bottom: 3rem;
        }

        &-details-para{
            font-size: 1.8rem;
            color: #666;
            max-width: 60rem;
            line-height: 1.7;
            margin-bottom: 1rem;

            &:last-child{
                margin-bottom: 4rem;
            }
        }
    }

    .btn{
        background: #7843e9;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: inline-block;
        font-weight: 700;
        border-radius: 5px;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
        transition: background .3s;
        padding: 1.5rem 5rem;
        font-size: 1.6rem;

        &:hover{
            background: #552ea9;
        }
    }

    .skills{
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        &__skill{
            padding: 1rem 2rem;
            font-size: 1.6rem;
            background: rgba(153,153,153,.2);
            border-radius: 5px;
            font-weight: 600;
            color: #666;
            cursor: pointer;
            transition: all .2s;

            &:hover{
                background-color: #7843e9;
                color: #fff;
            }
        }
    }
}
@media (max-width: 768px){
    .about__content{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10rem;
    }
}