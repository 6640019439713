.project{
    padding: 12rem 0;

    .projects__row{
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        grid-gap: 5rem;
        margin-bottom: 11rem;

        &-img-cont{
            overflow: hidden;

            img{
                width: 100%;
                display: block;
                object-fit: cover;
                height: 100%;
            }
        }

        &-content{
            padding: 2rem 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
        }

        &-content-title{
            font-weight: 700;
            font-size: 2.8rem;
            margin-bottom: 2rem;
        }

        &-content-desc{
            font-size: 1.8rem;
            color: #666;
            max-width: 60rem;
            line-height: 1.7;
            
            &:nth-child(odd){
                margin-bottom: 3rem;
            } 
                
        }

        &_button{
            background-color: #7843e9;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: 1px;
            display: inline-block;
            font-weight: 700;
            border-radius: 5px;
            box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
            transition: background .3s;
            padding: 1.5rem 5rem;
            font-size: 1.6rem;

            &:hover{
                background-color: #552ea9;
            }
        }

    }
}

@media (max-width: 768px){
    .project .projects__row{
        grid-template-columns: 1fr;
    }

    .projects__row_button{
        width: 100%;
        text-align: center;
    }
}